<template>
    <div>
        <pull-to :top-load-method="refresh" :top-config="TOP_DEFAULT_CONFIG" :is-bottom-bounce="false"
            :is-top-bounce="on_top">
            <template slot="top-block" slot-scope="props">
                <div class="top-load-wrapper text-center w-100">
                    <b-spinner v-if="props.state === 'trigger'" label="Loading..." />
                    <span v-else>{{ props.stateText }}</span>
                </div>
            </template>
            <div v-show="isLoading" class="loader" />
            <b-card no-body class="card-employee-task">
                <b-card-header>
                    <b-card-title>My Apps</b-card-title>
                    <feather-icon icon="ShoppingBagIcon" size="15" class="cursor-pointer" />
                </b-card-header>
                <b-card-body v-if="myAppsData && myAppsData.length">
                    <b-row>
                        <b-col v-for="(app,index) in myAppsData" :key="index" cols="12" md="6">
                            <div class="employee-task">
                                <b-link :to="{ name: 'app-detail',params: { app:app.id }}"
                                    class="d-flex justify-content-between align-items-center">
                                    <b-media no-body>
                                        <b-media-aside class="mr-75">
                                            <b-avatar rounded size="42" :src="app.icon?backend_url+app.icon:''"
                                                :text="avatarText(app.name)" />
                                        </b-media-aside>
                                        <b-media-body class="my-auto">
                                            <h6 class="mb-0">
                                                {{ app.name }}
                                            </h6>
                                            <div class="d-flex align-items-center">
                                                <small class="text-muted mr-75">Last Build: {{
                                                dateFormat(app.lastUpdated)
                                                }}</small>
                                            </div>
                                        </b-media-body>
                                    </b-media>
                                    <div class="d-flex">
                                        <social-icons v-if="app.android" :social-type="app.android" :still="'mr-50'"
                                            :size="'15'" />
                                        <social-icons v-if="app.ios" :social-type="app.ios" :size="'17'" />
                                    </div>
                                </b-link>
                                <hr>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-body>
                <b-card-body v-else-if="!myAppsData.length && !isUpdate">
                    <b-col>
                        <div class="w-100 text-center mt-1">
                            <h4 class="mb-1">
                                Apps not found 🚀
                            </h4>
                        </div>
                    </b-col>
                </b-card-body>
                <div v-if="!searchControl && totalPages >= page" v-observe-visibility="loadMoreApps"
                    class="d-flex justify-content-center w-100 mb-1">
                    <b-spinner label="Loading..." />
                </div>
            </b-card>
        </pull-to>
    </div>
</template>

<script>
import {
    BCard, BCardTitle, BCardHeader, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, BLink, BRow, BCol, BSpinner
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import useAppApi from '@/composables/useAppApi'
import moment from 'moment'
import SocialIcons from '@/components/SocialIcons.vue'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import PullTo from 'vue-pull-to'

export default {
    components: {
        BCard,
        BCardTitle,
        BCardHeader,
        BCardBody,
        BMedia,
        BMediaAside,
        BMediaBody,
        BAvatar,
        BLink,
        BRow,
        BCol,
        SocialIcons,
        BSpinner,
        PullTo
    },
    computed: {
        ...mapState('appStore', ['myAppsData', 'isUpdate', 'searchControl', 'page', 'totalPages']),
    },
    data() {
        return {
            backend_url: process.env.VUE_APP_BACKEND_URL,
            perPage: 30,
            device: null,
            on_top: true,
        }
    },
    mounted() {
        window.addEventListener("scroll", () => { this.on_top = window.pageYOffset <= 50 })
        store.dispatch('appStore/setStatus', true)
        store.dispatch('appStore/incPage', 0)
        this.deviceRead()
        this.loadMoreApps()
    },
    methods: {
        refresh(loaded) {
            store.dispatch('appStore/resetMyApps').then(response => {
                store.dispatch('appStore/incPage', 0).then(response => {
                    this.loadMoreApps()
                })
            })

            loaded('done')
        },
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD HH:mm')
        },
        deviceRead() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera
            if (/android/i.test(userAgent)) {
                this.device = 'android'
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                this.device = 'ios'
            }
        },
        loadMoreApps() {
            setTimeout(() => {
                store.dispatch('appStore/incPage', this.page + 1)
                    .then(response => {
                        store.dispatch('appStore/fetchMyApps', {
                            os: this.device,
                            perPage: this.perPage,
                            page: this.page,
                        }).then(({ data }) => {
                            if (data.pagination.totalPages <= this.page) store.dispatch('appStore/resetInfinite', true)
                            else store.dispatch('appStore/resetInfinite', false)
                        });
                    })
            }, 0);
        },
    },
    setup() {
        const {
            isLoading,
            TOP_DEFAULT_CONFIG
        } = useAppApi()

        return {
            isLoading,
            TOP_DEFAULT_CONFIG,
            avatarText,
        }
    },
}
</script>

<style>
.loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #f9b81c;
    width: 32px;
    height: 32px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 999;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
